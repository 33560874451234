import api from "../api";

const state = {
  plan: {},
};

const getters = {
  plan: (state) => {
    return state.plan;
  },

  currentPlan: (state) => {
    return state.plan.currentPlan;
  },

  currentPricing: (state) => {
    return state.plan.pricings.find(pricing.name === state.currentPlan);
  },

  userIsTrial: (state) => {
    return state.plan.isTrial;
  },

  accountIsLocked: (state) => {
    return state.plan.isLocked;
  },

  hasPaymentMethod: (state) => {
    return state.plan?.hasPaymentMethod;
  },

  planPricings: (state) => {
    return state.plan.pricings;
  },

  showWatermark: (state) => {
    return state.plan.showWatermark || false;
  },

  canRemoveWatermark: (state) => {
    return (
      state.plan.currentPlan === "growth" || state.plan.currentPlan === "pro"
    );
  },

  campaignLimit: (state) => {
    if (state.plan.currentPlan) {
      const currentPlan = state.plan.pricings.find(
        (el) => el.name === state.plan.currentPlan
      );
      if (currentPlan.activeCampaignLimit) {
        return currentPlan.activeCampaignLimit;
      }
    }
  },

  videoViewsLimit: (state) => {
    if (state.plan.isTrial && !state.plan.currentPlan) return 300;

    if (state.plan.currentPlan) {
      const currentPlan = state.plan.pricings.find(
        (el) => el.name === state.plan.currentPlan
      );

      return currentPlan?.videoViewLimit;
    }
  },

  videosInCampaignsLimit: (state) => {
    if (state.plan.isTrial && !state.plan.currentPlan) return 5;

    if (state.plan.currentPlan) {
      const currentPlan = state.plan.pricings.find(
        (el) => el.name === state.plan.currentPlan
      );

      return currentPlan?.videosInCampaignsLimit;
    }
  },

  // return the name of the plan above the user's current plan
  nextPlan: (state) => {
    let index = state.plan.pricings.findIndex(
      (plan) => plan.name === state.plan.currentPlan
    );
    // if index is 3 then user is prime and there is no plan above, so just display prime
    index = index === 3 ? index : index + 1;
    return state.plan.pricings[index].name;
  },

  bannerLocked: (state) => {
    return (
      (!state.plan.shopifyChargeAccepted &&
        state.plan.currentPlan !== "free" &&
        !state.plan.isLocked &&
        Boolean(state.plan.shopifyChargeId)) ||
      state.plan.isLocked
    );
  },

  isLocked: (state) => {
    return state.plan.isLocked;
  },

  cardExpDate: (state) => {
    if (state.plan.expMonth < 10) {
      return "0" + state.plan.expMonth + "/" + state.plan.expYear;
    } else {
      return state.plan.expMonth + "/" + state.plan.expYear;
    }
  },

  daysLeftTrial: (state) => {
    if (state.plan.isTrial && state.plan.trialEndDate) {
      const a = new Date(state.plan.trialEndDate);
      const b = new Date();
      const MS_PER_DAY = 1000 * 60 * 60 * 24;

      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc1 - utc2) / MS_PER_DAY);
    }
  },
};

const mutations = {
  GET(state, plan) {
    state.plan = plan;
  },
  UPDATE(state, plan) {
    state.plan = plan;
  },
};

const actions = {
  async getPlan({ commit }, { siteId }) {
    try {
      const plan = await api.getPlan(siteId);
      commit("GET", plan);
    } catch (err) {
      throw err;
    }
  },
  async addCreditCard({ commit }, { dataToAdd }) {
    try {
      await api.addCreditCard(dataToAdd);
    } catch (err) {
      throw err;
    }
  },
  async updatePlan({ commit }, { data }) {
    try {
      const { plan, invoice, isAgencyUpdated } = await api.updatePlan(data);
      if (invoice) {
        commit("invoices/ADD_LAST_INVOICE", invoice, { root: true });
      }
      if (isAgencyUpdated) {
        commit("account/toggleIsAgency", null, { root: true });
      }
      commit("UPDATE", plan);
    } catch (err) {
      throw err;
    }
  },
  async removeWatermark({ commit }, { siteId }) {
    try {
      const plan = await api.removeWatermark(siteId);
      commit("UPDATE", plan);
    } catch (err) {
      throw err;
    }
  },
  async getProposedPlan({ commit }, { siteId }) {
    try {
      const proposedPlan = await api.getProposedPlan(siteId);
      return proposedPlan;
    } catch (err) {
      throw err;
    }
  },
  async reactivateSubscription({ commit }, { siteId }) {
    try {
      const plan = await api.reactivateSubscription(siteId);
      commit("UPDATE", plan);
    } catch (err) {
      throw err;
    }
  },
  async addCoupon({ commit }, { siteId, couponCode }) {
    try {
      const plan = await api.applyCoupon(siteId, couponCode);
      commit("UPDATE", plan);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
