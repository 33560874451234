import { mapGetters } from "vuex";

export const tagMixin = {
  computed: {
    ...mapGetters({
      getTags: "tag/getTags",
    }),
  },
  methods: {
    async addBulkTag() {
      let tag;

      const bulkTag = this.getTags.find((tag) => tag.name === "Product pages");

      if (bulkTag?._id) {
        tag = { ...bulkTag };
      } else {
        tag = await this.$store.dispatch("tag/createTag", {
          name: "Product pages",
          siteId: this.siteId,
        });
      }

      return tag;
    },
  },
};
