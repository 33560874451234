<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="body_1"
    viewBox="0 0 450 450"
    width="100%" 
    height="100%"
  >
    <g>
      <path
        class="play-icon"
        d="M450 225C 450 287.13205 428.03302 340.16507 384.09903 384.09903C 340.16507 428.03302 287.13205 450 225 450C 162.86797 450 109.83496 428.03302 65.90097 384.09903C 21.966991 340.16507 0 287.13205 0 225C 0 162.86797 21.966991 109.83496 65.90097 65.90097C 109.83496 21.966991 162.86797 0 225 0C 287.13205 0 340.16507 21.966991 384.09903 65.90097C 428.03302 109.83496 450 162.86797 450 225C 450 227.61826 449.9543 230.23572 449.86295 232.85239"
        stroke="none"
        :fill="secondaryColor"
        :style="{ opacity: opacity }"
        fill-rule="nonzero"
      />
      <path
        class="play-icon"
        d="M321.024 218.622C 326.325 221.679 326.325 229.321 321.024 232.378L321.024 232.378L170.928 318.924C 165.627 321.981 159 318.16 159 312.046L159 312.046L159 138.954C 159 132.84 165.627 129.019 170.928 132.076L170.928 132.076L321.024 218.622z"
        stroke="none"
        :fill="color"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
  
  <script>
export default {
  name: "PlayRound",
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>