

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="body_1"
    viewBox="0 0 306 350"
    width="100%" 
    height="100%"
  >
    <g>
      <path
        class="play-icon"
        d="M298.671 162.263C 308.443 167.924 308.443 182.076 298.671 187.737L298.671 187.737L21.988 348.008C 12.2155 353.668 0 346.592 0 335.271L0 335.271L0 14.7291C 0 3.40749 12.2156 -3.66845 21.988 1.99233L21.988 1.99233L298.671 162.263z"
        stroke="none"
        :fill="color"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
  
  <script>
export default {
  name: "PlayMinimal",
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>