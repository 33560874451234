export async function resizeImage(url, targetWidth = 150) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Allows loading images from a different origin
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const targetHeight = targetWidth * aspectRatio;
      const canvas = document.createElement("canvas");
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
      const resizedDataUrl = canvas.toDataURL("image/png");
      resolve(resizedDataUrl);
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}