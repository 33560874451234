<template>
  <div
    class="bulk-embed-container"
    :class="{ 'bulk-embed-fixed': isSlidingModalOpen }"
  >
    <button class="close-icon" @click="goToCampaignManager(true)">
      <CloseIcon />
    </button>
    <div class="bulk-header">
      <h1 class="bulk-title">{{ $t("create.bulk.title") }}</h1>
      <div class="header-button">
        <div class="last-saved">Last saved: {{ lastSavedTime }}</div>
        <button
          class="bulk-action-button first-button"
          @click="openElementsSlidingModal(0, true)"
        >
          <PlayIcon />{{ $t("create.bulk.headerButtons.settings") }}
        </button>
        <button
          class="bulk-action-button second-button"
          @click="openEmbedCodeModal"
        >
          <CodeIcon />{{ $t("create.bulk.headerButtons.embedCode") }}
        </button>
      </div>
    </div>
    <div class="feed-list">
      <div class="left">
        <MultipleUsers />
        <h2 class="feed-title">{{ $t("create.bulk.feedList") }}</h2>
      </div>
      <h3 class="number-feeds">
        {{ feedList.length }} {{ $t("create.bulk.numberOfFeeds") }}
      </h3>
    </div>
    <list-items
      class="feed-list-header"
      :header-elements="[
        $t('create.bulk.headerElements.names'),
        $t('create.bulk.headerElements.pages') + segmentationAmount,

        $t('create.bulk.headerElements.videos') + videosAmount,
        $t(''),
      ]"
    >
    </list-items>
    <div class="feed-cards-container">
      <div
        v-for="(feed, index) in feedList"
        :key="index"
        :ref="`feed-${index}`"
        class="feed-card"
        :class="{ 'edited-feed': index === lastEditedFeedIndex }"
      >
        <div class="feed-name-and-date">
          <input
            v-model="feed.feedName"
            v-tippy="{ placement: 'top', arrow: true }"
            class="feed-name-input"
            :content="feed.feedName"
            @input="onFeedNameInput(index, feed.feedName)"
          />
          <p v-if="feed.creationDate" class="date">
            {{ $t("campaignManager.creationDate") }}
            {{ formattedDates(feed.creationDate) }}
          </p>
        </div>
        <div class="feed-info">
          <button class="action-button" @click="openUrlsSlidingModal(index)">
            {{ $t("create.bulk.mainButtons.urls") }}
          </button>
          <p class="info-number">{{ getAmountOfSegmentation(feed) }}</p>
        </div>
        <!-- <div class="feed-info">
          <button class="action-button" @click="openVideosSlidingModal(index)">
            {{ $t("create.bulk.mainButtons.videos") }}
          </button>
          <p class="info-number">{{ feed.videos.length }}</p>
        </div> -->
        <div class="feed-info-elements">
          <button
            class="action-button"
            @click="openElementsSlidingModal(index)"
          >
            {{ $t("create.bulk.mainButtons.videos") }}
          </button>
          <p class="info-number">{{ feed.videos.length }}</p>
          <div class="elements-icons">
            <div v-for="ctaType in uniqueCTATypes(feed)" :key="ctaType">
              <div v-if="ctaType === 1">
                <CopyIcon
                  v-tippy="{ placement: 'top', arrow: true }"
                  class="cta-icon"
                  :content="copy"
                />
              </div>
              <div v-else-if="ctaType === 0">
                <OpenIcon
                  v-tippy="{ placement: 'top', arrow: true }"
                  class="cta-icon"
                  :content="link"
                />
              </div>
              <div v-else-if="ctaType === 2">
                <EmailIcon
                  v-tippy="{ placement: 'top', arrow: true }"
                  class="cta-icon"
                  :content="email"
                />
              </div>
              <div v-else-if="ctaType === 3">
                <CartIcon
                  v-tippy="{ placement: 'top', arrow: true }"
                  class="cta-icon"
                  :content="cart"
                />
              </div>
            </div>
          </div>
        </div>
        <button class="option-button" @click="openOptionsModal(index)">
          <img
            src="@/assets/campaign-options-button.png"
            alt="options-button"
          />
        </button>
        <OptionsFeedModal
          v-if="showOptionsFeedModal[index]"
          :current-feed-edit-index="index"
          @clone-feed="handleCloneFeed"
          @open-delete-feed-modal="openDeleteFeedModal(index)"
          @close-options-modal="handleCloseOptionsModal(index)"
        />
      </div>
    </div>
    <button class="action-button create-feed" @click="createFeed">
      {{ $t("create.bulk.createFeed") }}
    </button>
    <small-modal
      v-if="showDeleteFeedModal"
      :title="$t('create.bulk.deleteFeedModal.title')"
      class="delete-feed-modal"
      :fit-content="true"
      @close="closeDeleteFeedModal"
    >
      <div class="delete-feed-modal-container">
        <h3 class="subtitle">
          {{ $t("create.bulk.deleteFeedModal.subtitle") }}
        </h3>
        <banner-modal
          :title="$t('deleteCampaign.information')"
          :text="$t('create.bulk.deleteFeedModal.banner')"
        >
        </banner-modal>
        <div class="buttons">
          <button
            class="button-primary --grey"
            type="button"
            @click.prevent="closeDeleteFeedModal"
          >
            {{ $t("deleteCampaign.cancel") }}
          </button>
          <button class="button-primary button-flex" @click="deleteFeed()">
            {{ $t("deleteCampaign.delete") }}
            <DeleteIcon class="delete-icon" />
          </button>
        </div>
      </div>
    </small-modal>
    <transition name="modal-slide">
      <urls-sliding-modal
        v-if="showUrlsModal"
        :current-feed-edit-index="currentFeedEditIndex"
        @close-urls-modal="closeUrlsModal"
      ></urls-sliding-modal>
    </transition>
    <transition name="modal-slide">
      <videos-sliding-modal
        v-if="showVideosModal"
        :current-feed-edit-index="currentFeedEditIndex"
        @close-videos-modal="closeVideosModal"
      >
      </videos-sliding-modal>
    </transition>
    <transition name="modal-slide">
      <elements-sliding-modal
        v-if="showElementsModal"
        :current-feed-edit-index="currentFeedEditIndex"
        :campaign-id="campaign._id"
        :is-edit-bulk-format="isEditBulkFormat"
        :is-bulk="true"
        @close-elements-modal="closeElementsModal"
      >
      </elements-sliding-modal>
    </transition>
    <transition name="modal-slide">
      <embed-code-sliding-modal
        v-if="showEmbedCodeModal"
        :campaign="getFullCampaign"
        @close-embed-code-modal="closeEmbedCodeModal"
      ></embed-code-sliding-modal>
    </transition>
  </div>
</template>

<script>
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import ListItems from "../shared/ListItems.vue";
import PlayIcon from "@/assets/svg/small-play-icon.svg?inline";
import CodeIcon from "@/assets/svg/embed-code-icon.svg?inline";
import MultipleUsers from "@/assets/svg/multiple-users-icon.svg?inline";
import UrlsSlidingModal from "@/components/create-campaign-steps/UrlsSlidingModal.vue";
import VideosSlidingModal from "@/components/create-campaign-steps/VideosSlidingModal.vue";
import ElementsSlidingModal from "@/components/create-campaign-steps/ElementsSlidingModal.vue";
import SmallModal from "@/components/shared/SmallModal.vue";
import BannerModal from "@/components//shared/BannerModal.vue";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import { mapState, mapGetters } from "vuex";
import EmbedCodeSlidingModal from "@/components/create-campaign-steps/EmbedCodeSlidingModal.vue";
import CartIcon from "@/assets/svg/cta/cart-icon.svg?inline";
import OpenIcon from "@/assets/svg/cta/open-icon.svg?inline";
import CopyIcon from "@/assets/svg/cta/copy-icon.svg?inline";
import EmailIcon from "@/assets/svg/cta/email-icon.svg?inline";
import OptionsFeedModal from "@/components/create-campaign-steps/OptionsFeedModal.vue";
import { tagMixin } from "@/utils/add-bulk-tag.js";
import enums from "../../enums";

const { FormatType, DeviceType } = enums;

export default {
  components: {
    CloseIcon,
    ListItems,
    PlayIcon,
    CodeIcon,
    MultipleUsers,
    UrlsSlidingModal,
    SmallModal,
    BannerModal,
    DeleteIcon,
    VideosSlidingModal,
    ElementsSlidingModal,
    EmbedCodeSlidingModal,
    CartIcon,
    OpenIcon,
    CopyIcon,
    EmailIcon,
    OptionsFeedModal,
  },
  mixins: [tagMixin],
  props: {
    isEditMode: { type: Boolean, default: true },
  },
  data() {
    return {
      showUrlsModal: false,
      showVideosModal: false,
      showDeleteFeedModal: false,
      showElementsModal: false,
      currentFeedEditIndex: null,
      showEmbedCodeModal: false,
      isEditBulkFormat: false,
      feedIndexToDelete: null,
      wasEmbedCodeOpened: false,
      copy: "Copy coupon code",
      cart: "Add to cart",
      email: "Email collector",
      link: "Link to page",
      showOptionsFeedModal: [],
      savedScrollPosition: 0,
      lastEditedFeedIndex: null,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
      campaign: "campaign/getSingleCampObj",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      defaultStoryFormatObject: "campaign/defaultStoryFormatObject",
      defaultCarouselFormatObject: "campaign/defaultCarouselFormatObject",
      defaultFormatObject: "campaign/defaultFormatObject",
      defaultEmbedObject: "campaign/defaultEmbedFormatObject",
      defaultBubbleProperties: "campaign/defaultBubbleProperties",
      getTags: "tag/getTags",
    }),
    segmentationAmount() {
      return this.feedList.reduce(
        (total, feed) => total + this.getAmountOfSegmentation(feed),
        0
      );
    },
    videosAmount() {
      return this.feedList.reduce(
        (total, feed) => total + feed.videos.length,
        0
      );
    },
    getFullCampaign() {
      return {
        ...this.campaign,
        bulk: this.feedList,
      };
    },
    isSlidingModalOpen() {
      return (
        this.showUrlsModal ||
        this.showVideosModal ||
        this.showElementsModal ||
        this.showEmbedCodeModal
      );
    },
    lastSavedTime() {
      if (!this?.campaign?.modificationDate) {
        return "";
      }

      const modificationDate = new Date(this.campaign.modificationDate);
      const currentDate = new Date();
      const diffInMs = currentDate - modificationDate;
      const diffInSeconds = Math.floor(diffInMs / 1000);

      if (diffInSeconds < 60) {
        return `${diffInSeconds} seconds ago`;
      }

      const diffInMinutes = Math.floor(diffInSeconds / 60);
      if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
      }

      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours < 24) {
        return `${diffInHours} hours ago`;
      }

      const diffInDays = Math.floor(diffInHours / 24);
      if (diffInDays < 7) {
        return `${diffInDays} days ago`;
      }

      const diffInWeeks = Math.floor(diffInDays / 7);
      if (diffInDays < 30) {
        return `${diffInWeeks} weeks ago`;
      }

      const diffInMonths = Math.floor(diffInDays / 30);
      return `${diffInMonths} months ago`;
    },
  },
  async created() {
    try {
      const { goal, type } = this.$route.query;

      if (this.isEditMode) {
        await this.$store.dispatch("campaign/getSingle", {
          campaignId: this.$route.params.campaignId,
          siteId: this.siteId,
        });
      } else {
        const formats = this.getFormatsObject({ goal, type });
        const tag = await this.addBulkTag();

        await this.$store.dispatch("campaign/createBulkCampaign", {
          siteId: this.siteId,
          formats,
          tags: [tag._id],
        });
      }
      this.$store.commit("campaign/setFeedList", this.campaign.bulk);
    } catch (err) {
      this.$notify({
        title: this.$t("shared.toastMessage.error"),
        text: err,
        type: "error",
      });
      throw err;
    }
    this.feedList.forEach(() => {
      this.showOptionsFeedModal.push(false);
    });
  },
  methods: {
    openUrlsSlidingModal(index) {
      this.currentFeedEditIndex = index;
      this.$store.commit("campaign/setCurrentFeedEdit", this.feedList[index]);
      this.savedScrollPosition = window.pageYOffset;
      this.showUrlsModal = true;
    },
    openVideosSlidingModal(index) {
      this.currentFeedEditIndex = index;
      this.$store.commit("campaign/setCurrentFeedEdit", this.feedList[index]);
      this.savedScrollPosition = window.pageYOffset;
      this.showVideosModal = true;
    },
    openElementsSlidingModal(index, isFormatEdit = false) {
      //This way we know if we are editing Formats or Videos elements
      this.isEditBulkFormat = isFormatEdit;

      this.currentFeedEditIndex = index;
      this.$store.commit("campaign/setCurrentFeedEdit", this.feedList[index]);
      this.savedScrollPosition = window.pageYOffset;

      if (this.isEditBulkFormat && !this.feedList[0]?.videos) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.addVideosFirstFeed"),
          type: "error",
        });
      }

      this.showElementsModal = true;
    },
    openEmbedCodeModal() {
      if (!this.feedList.length || !this.feedList[0]?.videos?.length) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.firstFeedCode"),
          type: "error",
        });
      }
      this.savedScrollPosition = window.pageYOffset;
      this.showEmbedCodeModal = true;
      this.wasEmbedCodeOpened = true;
    },
    closeUrlsModal() {
      this.lastEditedFeedIndex = this.currentFeedEditIndex;
      this.showUrlsModal = false;
      this.scrollToSavedPosition();
      setTimeout(() => {
        this.lastEditedFeedIndex = null;
      }, 3000);
    },
    closeVideosModal() {
      this.lastEditedFeedIndex = this.currentFeedEditIndex;
      this.showVideosModal = false;
      this.scrollToSavedPosition();
      setTimeout(() => {
        this.lastEditedFeedIndex = null;
      }, 3000);
    },
    closeElementsModal() {
      if (!this.isEditBulkFormat) {
        this.lastEditedFeedIndex = this.currentFeedEditIndex;
      }
      this.showElementsModal = false;
      this.scrollToSavedPosition();
      if (!this.isEditBulkFormat) {
        setTimeout(() => {
          this.lastEditedFeedIndex = null;
        }, 3000);
      }
    },
    closeEmbedCodeModal() {
      this.showEmbedCodeModal = false;
      this.scrollToSavedPosition();
    },

    goToCampaignManager(closeBulkView = false) {
      const query = {};

      if (
        !this.isEditMode &&
        !this.wasEmbedCodeOpened &&
        !closeBulkView &&
        this.campaign?.formats[0]?.formatType !== FormatType.bubble &&
        this.campaign?.formats[0]?.formatType !== FormatType.popup
      ) {
        query.showEmbed = true;
      }

      this.$router.push({
        name: "campaignManager",
        params: {
          campaignId: this.campaign._id,
        },
        query,
      });
    },

    async createFeed() {
      const newFeed = {
        feedName: "Feed name " + (this.feedList.length + 1),
        segmentation: {
          url: [],
        },
        videos: [],
        creationDate: new Date().toISOString(),
      };

      await this.$store.dispatch("campaign/createFeed", {
        campaignId: this.campaign._id,
        newFeed,
      });
      this.showOptionsFeedModal.push(false);
    },
    onFeedNameInput(index, name) {
      this.$store.dispatch("campaign/updateFeed", {
        campaignId: this.campaign._id,
        feedIndex: index,
        feedData: {
          ...this.feedList[index],
          feedName: name,
        },
      });
    },
    openDeleteFeedModal(index) {
      this.feedIndexToDelete = index;
      this.showDeleteFeedModal = true;
    },
    closeDeleteFeedModal() {
      this.showDeleteFeedModal = false;
    },
    deleteFeed() {
      this.$store.dispatch("campaign/deleteFeed", {
        campaignId: this.campaign._id,
        feedIndex: this.feedIndexToDelete,
      });

      this.showOptionsFeedModal.splice(this.feedIndexToDelete, 1);
      this.closeDeleteFeedModal();
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.deleteFeed"),
        type: "success",
      });
    },
    getAmountOfSegmentation(feed) {
      if (!feed.segmentation.url || !feed.segmentation.url.length) {
        return feed.segmentation.productId
          ? feed.segmentation.productId.length
          : 0;
      } else if (!feed.segmentation.url[0]?.filters) {
        return feed.segmentation.productId
          ? feed.segmentation.productId.length + 1
          : 1;
      } else {
        return (
          feed.segmentation.url[0]?.filters.length +
          (feed.segmentation.productId ? feed.segmentation.productId.length : 0)
        );
      }
    },
    getFormatsObject({ goal, type }) {
      switch (goal) {
        case "carousel":
          if (type === "fullScreen")
            return [
              {
                ...this.defaultFormatObject,
                ...this.defaultCarouselFormatObject,
                isFullScreen: true,
                carouselThumbnailAmount: 1,
              },
            ];
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultCarouselFormatObject,
              isFullScreen: false,
            },
          ];
        case "stories":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultStoryFormatObject,
            },
          ];
        case "embed":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultEmbedObject,
            },
          ];
        case "bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.desktop,
            },
          ];
        case "fullscreen-bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              isFullScreen: true,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              isFullScreen: true,
              deviceType: DeviceType.desktop,
            },
          ];
        case "popup":
          return [
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.desktop,
            },
          ];
      }
    },
    uniqueCTATypes(feed) {
      const uniqueTypes = new Set();
      feed.videos.forEach((video) => {
        if (video.cta) {
          uniqueTypes.add(video.cta.ctaType);
        }
      });
      return Array.from(uniqueTypes);
    },
    openOptionsModal(index) {
      this.currentFeedEditIndex = index;
      this.showOptionsFeedModal = this.showOptionsFeedModal.map(
        (value, i) => i === index
      );
    },
    handleCloseOptionsModal(index) {
      this.$set(this.showOptionsFeedModal, index, false);
    },
    handleCloneFeed(index) {
      const feedToClone = this.feedList[index];

      const clonedFeed = {
        ...feedToClone,
        feedName: `${feedToClone.feedName} (Copy)`,
        creationDate: new Date().toISOString(),
      };

      this.$store.dispatch("campaign/createFeed", {
        campaignId: this.campaign._id,
        newFeed: clonedFeed,
      });

      this.showOptionsFeedModal.push(false);
      this.handleCloseOptionsModal(index);
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.cloneFeed"),
        type: "success",
      });
      this.scrollToEndOfPage();
    },
    formattedDates(date) {
      const invoicingDate = new Date(date);
      const month = invoicingDate.toLocaleString(this.$i18n.locale, {
        month: "short",
      });
      return `${month}  ${date.substring(8, 10)},  ${date.substring(0, 4)} `;
    },
    scrollToEndOfPage() {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    },
    scrollToSavedPosition() {
      setTimeout(() => {
        window.scrollTo({ top: this.savedScrollPosition, behavior: "smooth" });
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.feed-list-header .list-header {
  grid-template-columns: 2.5fr 1fr 1.5fr 0.5fr !important;
  margin-bottom: 12px;
}
</style>

<style lang="scss" scoped>
.bulk-embed-container {
  padding: 30px 74px;
}

.bulk-embed-fixed {
  position: fixed;
}

.close-icon {
  top: 10px;
  right: 10px;
  background: none;
  position: fixed;
}

.bulk-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}

.bulk-title {
  @include base-font;
  @include font-big;
  font-weight: 700;
}

.header-button {
  display: flex;
}

.feed-list-header {
  margin-bottom: 12px;
}

.bulk-action-button {
  padding: 8px 24px;
  border: 1px solid $medium-grey;
  background-color: white;
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  &:hover {
    background-color: rgba(226, 124, 252, 0.1);
  }
}

.first-button {
  border-radius: 3px 0px 0px 3px;
  border-right: none;
}

.second-button {
  border-radius: 0 3px 3px 0;
}

.last-saved {
  padding: 8px 12px;
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  color: $light-grey;
}

.save-icon {
  path {
    fill: white;
  }
}

.feed-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.feed-title {
  @include base-font;
  @include font-big;
}
.number-feeds {
  @include base-font;
  @include font-normal;
}

.feed-cards-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.feed-card {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1.5fr 0.5fr;
  place-items: flex-start;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 5px;
  padding: 12px 14px;
  box-sizing: content-box;
  position: relative;
  // @media (max-width: 1100px) {
  //   grid-template-columns: 1.5fr 1.15fr 1.15fr 1.25fr 0.25fr;
  // }
}
.feed-name-input {
  @include base-font;
  @include font-normal;
  border: none;
  @media (min-width: 1400px) {
    width: 90%;
  }
}

.feed-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.info-number {
  @include base-font;
  @include font-normal;
}
.create-feed {
  width: 180px;
  float: left;
  margin-top: 12px;
  margin-bottom: 24px;
}

.delete-feed-card {
  width: 100%;
  text-align: end;
  .delete-icon-card {
    transform: scale(225%);
    margin-right: 8px;
    cursor: pointer;
    path {
      fill: $light-grey;
    }
  }
}

.delete-feed-modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.subtitle {
  text-align: center;
  @include font-normal;
  font-weight: 400;
}

.delete-icon {
  transform: scale(175%);
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}

.--red {
  box-shadow: 2px 2px 15px 0px rgba(235, 0, 0, 0.25);
  &:hover {
    background-color: rgba(235, 0, 0, 0.25);
  }
}

.feed-info-elements {
  display: flex;
  align-items: center;
  gap: 16px;
}

.elements-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cta-icon {
  transform: scale(115%);
  outline: none;
}
.option-button {
  background: transparent;
}

.feed-name-and-date {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 90%;
}

.date {
  @include font-smallest;
  color: $light-grey;
  padding-left: 2px;
}
.edited-feed {
  border: 1px solid $dark-purple;
}
</style>
