<template>
  <base-card>
    <template #icon>
      <CreditCardIcon />
    </template>
    <template #title>
      {{ $t("billing.creditCardInformation.title") }}
    </template>
    <button-pen
      v-show="
        site.site.integration !== 'shopify' ||
        (site.site.payByCard && !vatNumber)
      "
      class="button-pen"
      @click.native="openCreditCardModal"
    ></button-pen>

    <section class="grid-container">
      <div class="left-column">
        <div v-if="userIsShopify" class="credit-card-user">
          <img
            v-if="userIsShopify"
            src="../../../assets/shopify-bag-small.png"
            alt="shopify"
          />
          <p v-if="userIsShopify" class="shopify-info">
            {{ $t("billing.creditCardInformation.isShopify") }}
          </p>
        </div>
        <div v-else-if="userHasNoPaymentMethod">
          <p>
            {{ $t("billing.creditCardInformation.hasNoCard") }}
          </p>
        </div>
        <div v-else class="credit-card-information">
          <p class="card-info">{{ plan.plan.cardBrand }}</p>
          <p>{{ plan.plan.cardLastFourDigits }}</p>
          <p class="card-info">
            {{ $t("billing.creditCardInformation.isNotShopify.expDate") }}
          </p>
          <p>{{ cardExpDate }}</p>
        </div>
        <p v-if="billingDate" class="billing-date">
          {{ $t("billing.creditCardInformation.billingDate1") }}
          {{ billingDate }}
          {{ $t("billing.creditCardInformation.billingDate2") }}
        </p>
      </div>

      <div class="right-column">
        <div>
          <p
            v-if="!vatNumber && isIntraCommunity"
            class="add-vat-number"
            @click="toggleVatNumber"
          >
            {{
              Boolean(site.site.vatNumber)
                ? $t("billing.creditCardInformation.vat.updateVat")
                : $t("billing.creditCardInformation.vat.addVat")
            }}
          </p>
          <form v-else-if="vatNumber" class="form" @submit.prevent="updateVat">
            <div class="buttons">
              <button class="icon-button" @click="toggleVatNumber">
                <CrossX />
              </button>
              <button class="icon-button" @click="updateVat">
                <CrossChecked />
              </button>
            </div>
            <input
              id="vat"
              type="text"
              :value="site.site.vatNumber ? site.site.vatNumber : ''"
              @input="onVatUpdate"
            />
          </form>
        </div>

        <div class="coupon-wrapper">
          <p
            v-if="!isCouponInput"
            class="add-vat-number add-coupon"
            @click="toggleCouponInput"
          >
            {{ $t("billing.creditCardInformation.coupon") }}
          </p>
          <form
            v-else-if="isCouponInput"
            class="form"
            @submit.prevent="addCoupon"
          >
            <div class="buttons">
              <button class="icon-button" @click="toggleCouponInput">
                <CrossX />
              </button>
              <button class="icon-button" @click="addCoupon">
                <CrossChecked />
              </button>
            </div>
            <input id="coupon" type="text" @input="onCouponUpdate" />
          </form>
        </div>
      </div>
    </section>
  </base-card>
</template>

<script>
import CreditCardIcon from "@/assets/svg/settings/credit-card.svg?inline";
import BaseCard from "../../shared/BaseCard.vue";
import ButtonPen from "../../shared/Vidjet-pen-btn.vue";
import { mapState, mapGetters } from "vuex";
import VatCheck from "@/utils/intraCommunityVatCheck.js";
import CrossX from "@/assets/svg/circle-x-icon.svg?inline";
import CrossChecked from "@/assets/svg/circle-checked-icon.svg?inline";

export default {
  components: {
    CreditCardIcon,
    ButtonPen,
    BaseCard,
    CrossX,
    CrossChecked,
  },
  data() {
    return {
      vatNumber: false,
      allInfoChecked: false,
      vat: "",
      shopifyLogo: require("@/assets/shopify-logo-small.png"),
      isCouponInput: false,
      coupon: "",
    };
  },

  computed: {
    ...mapState(["plan", "site", "user"]),
    ...mapGetters({
      cardExpDate: "plan/cardExpDate",
      getAddress: "site/getAddress",
    }),

    userIsShopify() {
      return (
        this.site.site.integration === "shopify" && !this.site.site.payByCard
      );
    },
    userHasNoPaymentMethod() {
      return !this.plan.plan.hasPaymentMethod && !this.vatNumber;
    },
    billingDate() {
      if (!this.$store.state.plan.plan.billingCycleEndDate) return;
      let date = new Date(this.$store.state.plan.plan.billingCycleEndDate);
      return date.getDate();
    },
    isIntraCommunity() {
      if (!this.getAddress || !this.getAddress.country) {
        return false;
      }
      return VatCheck(this.getAddress.country);
    },
  },
  async created() {
    await this.$store.dispatch("user/getUser", {
      userId: this.$store.state.account.user._id,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    // checks wether or not the user has entered all his information to add payment
    if (
      Boolean(this.user.user.firstname) &&
      Boolean(this.user.user.lastname) &&
      Boolean(this.user.user.email) &&
      this.site.site.address &&
      this.site.site.address.line1 != "" &&
      Boolean(this.site.site.companyName)
    ) {
      this.allInfoChecked = true;
    } else {
      this.allInfoChecked = false;
    }
  },
  methods: {
    onVatUpdate(e) {
      this.vat = e.target.value;
    },
    async updateVat() {
      await this.$store.dispatch("site/updateSite", {
        siteId: this.site.site._id,
        dataToUpdate: { vatNumber: this.vat.toUpperCase() },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        ignoreDuplicates: true,
        text: this.$t("shared.toastMessage.vatAdded"),
        type: "success",
      });
      this.vatNumber = false;
    },
    toggleVatNumber() {
      this.vatNumber = !this.vatNumber;
    },
    // if user hasn't added all his infos user is redirected to account page
    openCreditCardModal() {
      if (this.allInfoChecked) {
        this.$emit("openCreditCardModal");
      } else {
        this.$router.push({ name: "account" });
        this.$notify({
          title: this.$t("shared.toastMessage.fillInformation"),
          type: "error",
        });
      }
    },
    toggleCouponInput() {
      this.isCouponInput = !this.isCouponInput;
    },
    onCouponUpdate(e) {
      this.coupon = e.target.value;
    },
    async addCoupon() {
      if (!this.coupon) {
        this.$notify({
          ignoreDuplicates: true,
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.noCoupon"),
          type: "error",
        });
        return;
      }

      try {
        await this.$store.dispatch("plan/addCoupon", {
          siteId: this.site.site._id,
          couponCode: this.coupon.trim(),
        });
        this.$notify({
          ignoreDuplicates: true,
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.couponAdded"),
          type: "success",
        });
        this.toggleCouponInput();
      } catch (err) {
        this.$notify({
          ignoreDuplicates: true,
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.wrongCoupon"),
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-card-user {
  @include font-small;
  display: flex;
  align-items: center;
  gap: 8px;
}

.credit-card-information {
  @include base-font;
  @include font-small;
  display: flex;
  justify-content: space-evenly;
  margin-right: 50px;
}

.card-info {
  color: $dark-grey;
  font-weight: 600;
}

.button-pen {
  position: absolute;
  top: 16px;
  right: 24px;
}

.form {
  display: flex;
  align-items: center;
  gap: 4px;
}

.add-vat-label {
  @include font-small;
}

.billing-block {
  @include font-small;
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}

.billing-date,
.shopify-info {
  color: $light-grey;
  @include font-small;
}

.add-vat-number {
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

#vat {
  height: 28px;
  @include input-style;
  align-self: center;
  width: 150px;
}

#coupon {
  height: 28px;
  @include input-style;
  align-self: center;
  width: 150px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 6px;
}

@include media(">tablet", "<=desktop") {
  .credit-card-user {
    width: 90%;
    margin-left: 32px;
  }
}

@include media(">phone", "<=tablet") {
  .credit-card-user {
    @include font-small;
    margin-left: 16px;
  }

  .credit-card-information {
    @include font-small;
    margin-right: 10px;
  }
}

@include media("<=phone") {
  .credit-card-user {
    @include font-smaller;
    margin-left: 16px;
  }

  .credit-card-information {
    @include font-smaller;
    margin-right: 10px;
  }
}
.icon-button {
  background: none;
  padding: 0;
}

.coupon-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1.8fr 1fr;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.right-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.coupon-wrapper,
.add-vat-number {
  justify-self: end;
}

.form {
  display: flex;
  align-items: center;
  gap: 4px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 6px;
}
</style>
