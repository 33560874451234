


<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="body_1"
    viewBox="0 0 450 450"
    width="100%" 
    height="100%"
  >
    <g>
      <path
        class="play-icon"
        d="M437.5 225C 437.50003 283.68027 416.75342 333.767 375.2602 375.2602C 333.767 416.75342 283.68027 437.50003 225 437.5C 166.31975 437.50003 116.23302 416.75342 74.73981 375.2602C 33.246605 333.767 12.500001 283.68027 12.5 225C 12.500001 166.31975 33.246605 116.23302 74.73981 74.73981C 116.23302 33.246605 166.31975 12.500001 225 12.5C 283.68027 12.500001 333.767 33.246605 375.2602 74.73981C 416.75342 116.23302 437.50003 166.31975 437.5 225C 437.50003 227.47281 437.45688 229.94485 437.37054 232.41614"
        :stroke="secondaryColor"
        :style="{ opacity: opacity }"
        stroke-width="25"
        fill="none"
      />
      <path
        class="play-icon"
        d="M321.024 218.622C 326.325 221.679 326.325 229.321 321.024 232.378L321.024 232.378L170.928 318.924C 165.627 321.981 159 318.16 159 312.046L159 312.046L159 138.954C 159 132.84 165.627 129.019 170.928 132.076L170.928 132.076L321.024 218.622z"
        stroke="none"
        :fill="color"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
  
  <script>
export default {
  name: "PlayOutline",
  props: {
    color: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
};
</script>
  
  <style lang="css" scoped>
</style>