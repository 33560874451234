<template>
  <!-- TODO add title in modal and buttons -->
  <div class="modal-background" @click.self="close">
    <slot name="overlay"></slot>
    <div class="small-modal" :class="[fitContent ? 'fit-modal' : 'bigger']">
      <button v-if="showCloseButton" class="close-icon" @click="close">
        <CloseIcon />
      </button>
      <h1 v-if="title" class="modal-title">{{ title }}</h1>
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/svg/settings/close.svg?inline";

export default {
  name: "SmallModal",
  components: {
    CloseIcon,
  },
  props: {
    showCloseButton: { type: Boolean, default: true },
    fitContent: { type: Boolean, default: false },
    title: { type: String, default: "" },
    closeOnBackgroundClick: { type: Boolean, default: true },
  },
  data() {
    return {
      halfBackground: false,
      modalLibrary: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-background {
  z-index: map-get($zindex, modal);
  background-color: $modal-bg;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.small-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 250px 100px rgba(0, 0, 0, 0.2),
    inset 0px 0px 10px 3px #dfdfdf;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}

.modal-title {
  margin-bottom: 16px;
  @include font-big;
  font-weight: 600;
  text-align: center;
  color: $dark-grey;
  white-space: nowrap;
}

.small-modal.fit-modal {
  height: fit-content;
  width: fit-content;
  padding: 44px 64px;
}

.small-modal.bigger {
  width: 80vw;
  max-width: 870px;
  height: fit-content;
  min-height: 460px;
  max-height: 650px;
  overflow-y: auto;
}

.close-icon {
  top: 10px;
  right: 10px;
  background: none;
  position: absolute;
  z-index: 501;
}

@include media(">phone", "<=tablet") {
  .small-modal {
    width: 95vw;
  }
}

@include media("<=phone") {
  .small-modal {
    width: 100vw;
  }
}
</style>
