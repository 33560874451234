<template>
  <div class="main-content-wrapper">
    <div class="header">
      <div class="title-subtitle">
        <h1 class="onboarding-title">{{ $t("onboarding.title") }}</h1>
        <h2 class="onboarding-subtitle">{{ $t("onboarding.subtitle") }}</h2>
      </div>
      <InstructionBanner
        class="onboarding-instruction-banner"
        selected-name="baptiste"
        :title-text="$t('onboarding.banner.title')"
        :link-text="$t('onboarding.banner.button')"
        :icons="flags"
        @handle-button-click="onboardingHelpRequest"
      />
    </div>
    <div class="onboarding-steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="step-box"
        :class="{
          'step-box-completed': numberOfProgress(index) == step.totalSmallSteps,
        }"
      >
        <div class="header-progress">
          <div class="step-header">
            <div class="title-tooltip">
              <h2 class="step-title">
                <span class="step-number">{{ index + 1 }}</span>
                {{ step.title }}
              </h2>
              <TooltipIcon
                v-tippy="{
                  arrow: true,
                  placement: 'right',
                  a11y: false,
                  maxWidth: 450,
                  boundary: 'window',
                }"
                class="tooltip-icon"
                :class="{
                  'tooltip-completed':
                    numberOfProgress(index) === step.totalSmallSteps,
                }"
                :content="step.tooltip"
              />
            </div>
            <svg
              class="accordion-icon"
              :class="{ isFullyCompleted: accordionStates[index] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 16 10"
              aria-hidden="true"
              @click="toggleAccordion(index)"
            >
              <path
                d="M15 1.2l-7 7-7-7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              :style="{
                width: `${
                  (numberOfProgress(index) / step.totalSmallSteps) * 100
                }%`,
              }"
              :class="{
                'half-completed':
                  numberOfProgress(index) / step.totalSmallSteps >= 0.5,
                'fully-completed':
                  numberOfProgress(index) / step.totalSmallSteps === 1,
              }"
            ></div>
            <p class="progress-number">
              {{ numberOfProgress(index) }} {{ $t("onboarding.of") }}
              {{ step.totalSmallSteps }} {{ $t("onboarding.completed") }}
            </p>
          </div>
        </div>
        <div v-if="!accordionStates[index]" class="small-steps">
          <div v-if="index === 1" class="small-step">
            <div class="small-step-title-checkbox">
              <div
                class="checkbox"
                :class="{
                  'full-checkbox': isSmallStepCompleted(index, 1),
                  'clickable-checkbox': index !== 0,
                }"
                @click="toggleCheckbox(index, 1)"
              >
                <CheckedStep />
              </div>
              <p
                class="small-step-title"
                :class="{
                  'small-step-title-completed': isSmallStepCompleted(index, 1),
                }"
              >
                {{ step.smallStep1 }}
              </p>
            </div>
            <a
              class="tutorial"
              :class="{
                'tutorial-completed': isSmallStepCompleted(index, 1),
              }"
              :href="step.link1"
              target="_blank"
              >{{ $t("onboarding.tutorial") }}<OpenTutorial
            /></a>
          </div>
          <div
            v-for="i in index === 1 ? [2, 3] : [1, 2]"
            :key="i"
            class="small-step"
          >
            <div class="small-step-title-checkbox">
              <div
                class="checkbox"
                :class="{
                  'full-checkbox': isSmallStepCompleted(index, i),
                  'clickable-checkbox': index !== 0,
                }"
                @click="toggleCheckbox(index, i)"
              >
                <CheckedStep />
              </div>
              <p
                class="small-step-title"
                :class="{
                  'small-step-title-completed': isSmallStepCompleted(index, i),
                }"
              >
                {{ step[`smallStep${i}`] }}
              </p>
            </div>
            <a
              class="tutorial"
              :class="{
                'tutorial-completed': isSmallStepCompleted(index, i),
              }"
              :href="step[`link${i}`]"
              target="_blank"
              >{{ $t("onboarding.tutorial") }}<OpenTutorial
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstructionBanner from "@/components/shared/InstructionBanner.vue";
import CheckedStep from "@/assets/svg/checked-step.svg?inline";
import OpenTutorial from "@/assets/svg/open_new_tab.svg?inline";
import TooltipIcon from "@/assets/svg/tooltip-icon.svg?inline";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    InstructionBanner,
    CheckedStep,
    OpenTutorial,
    TooltipIcon,
  },
  data() {
    return {
      steps: [
        {
          title: "Integrate Vidjet to your store",
          integrateMain: false,
          trackSales: false,
          smallStep1: this.$t("onboarding.step1"),
          smallStep2: this.$t("onboarding.step2"),
          tooltip: this.$t("onboarding.automaticTooltip"),
          link1: "https://help.vidjet.io/en/collections/3108200-integrations",
          link2:
            "https://help.vidjet.io/en/articles/5568648-how-to-track-orders-and-conversions",
          totalSmallSteps: 2,
        },
        {
          title: "Publish videos on product pages",
          importVideos: false,
          embedBulkComponent: false,
          publishScale: false,
          smallStep1: this.$t("onboarding.step3"),
          smallStep2: this.$t("onboarding.step4"),
          smallStep3: this.$t("onboarding.step5"),
          tooltip: this.$t("onboarding.manualTooltip"),
          link1:
            "https://help.vidjet.io/en/articles/9207815-import-and-manage-videos",
          link2:
            "https://help.vidjet.io/en/articles/8368680-embedding-the-master-component-on-your-page-template",
          link3:
            "https://help.vidjet.io/en/articles/8498754-get-started-with-the-bulk-embed-feature",
          totalSmallSteps: 3,
        },
        {
          title: "Drive traffic to your product pages",
          embedHomepage: false,
          embedLanding: false,
          smallStep1: this.$t("onboarding.step6"),
          smallStep2: this.$t("onboarding.step7"),
          tooltip: this.$t("onboarding.manualTooltip"),
          link1:
            "https://help.vidjet.io/en/articles/8779193-embed-a-carousel-on-the-homepage",
          link2:
            "https://help.vidjet.io/en/articles/8790660-embed-videos-on-landing-pages",
          totalSmallSteps: 2,
        },
      ],
      accordionStates: [false, false, false],
      flags: {
        gb: require("@/assets/flag-icon-uk.png"),
        fr: require("@/assets/flag-icon-fr.png"),
        es: require("@/assets/flag-icon-sp.png"),
      },
      propertyMap: [
        ["integrateMain", "trackSales"],
        ["importVideos", "embedBulkComponent", "publishScale"],
        ["embedHomepage", "embedLanding"],
      ],
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
  },
  async created() {
    await this.$store.dispatch("site/getSite", {
      siteId: this.siteId,
    });
    if (!this.site.site.onboarding) {
      await this.$store.dispatch("site/updateSite", {
        siteId: this.siteId,
        dataToUpdate: {
          onboarding: {},
        },
      });
    }
    if (this.site.site.integration) {
      this.steps[0].integrateMain = true;
      if (!this.site.site.onboarding?.integrateMain) {
        this.$store.dispatch("site/updateOnboarding", {
          property: "integrateMain",
          value: true,
        });
      }
    }
    if (this.site.site.integration && this.site.site.integration !== "manual") {
      this.steps[0].trackSales = true;
      if (!this.site.site.onboarding.trackSales) {
        this.$store.dispatch("site/updateOnboarding", {
          property: "trackSales",
          value: true,
        });
      }
    } else if (
      this.site.site.integration &&
      this.site.site.integration === "manual" &&
      this.site.site.onboarding.trackSales === true
    ) {
      this.steps[0].trackSales = true;
    }
    this.checkOnboardingStepsInDB();

    this.steps.forEach((step, index) => {
      const [property1, property2] = this.propertyMap[index];

      if (step[property1] && step[property2]) {
        this.$set(this.accordionStates, index, true);
      }
    });
  },
  methods: {
    isSmallStepCompleted(stepIndex, checkboxIndex) {
      const properties = this.propertyMap[stepIndex];
      return this.steps[stepIndex][properties[checkboxIndex - 1]];
    },
    numberOfProgress(index) {
      const completedCount = Object.values(this.steps[index]).filter(
        (value) => typeof value === "boolean" && value
      ).length;
      return `${completedCount}`;
    },
    toggleAccordion(index) {
      this.$set(this.accordionStates, index, !this.accordionStates[index]);
    },
    toggleCheckbox(stepIndex, checkboxIndex) {
      if (stepIndex === 0) {
        return;
      }

      const properties = this.propertyMap[stepIndex];
      const property = properties[checkboxIndex - 1];

      const currentValue = this.isSmallStepCompleted(stepIndex, checkboxIndex);

      this.$set(this.steps[stepIndex], property, !currentValue);

      this.$store.dispatch("site/updateOnboarding", {
        property,
        value: !currentValue,
      });
    },
    onboardingHelpRequest() {
      window.Intercom &&
        window.Intercom(
          "showNewMessage",
          `Hi! I would like assistance on my onboarding.
Can you please share examples and recommendations?`
        );
      window.Intercom &&
        window.Intercom(
          "showNewMessage",
          `Hi! I would like assistance on my onboarding.
Can you please share examples and recommendations?`
        );
    },
    checkOnboardingStepsInDB() {
      if (this.site.site.onboarding?.embedBulkComponent) {
        this.steps[1].importVideos = true;
      }
      if (this.site.site.onboarding?.embedBulkComponent) {
        this.steps[1].embedBulkComponent = true;
      }
      if (this.site.site.onboarding?.publishScale) {
        this.steps[1].publishScale = true;
      }
      if (this.site.site.onboarding?.embedHomepage) {
        this.steps[2].embedHomepage = true;
      }
      if (this.site.site.onboarding?.embedLanding) {
        this.steps[2].embedLanding = true;
      }
    },
  },
};
</script>

<style lang="scss">
.onboarding-instruction-banner.banner-wrapper {
  margin: 0;
}
</style>

<style lang="scss" scoped>
.main-content-wrapper {
  margin-left: 100px;
  margin-right: 30px;
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title-subtitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-tooltip {
  display: flex;
  align-items: center;
  gap: 4px;
}

.onboarding-title {
  @include base-font;
  @include font-giant;
  font-weight: 600;
}

.tooltip-icon {
  &:hover {
    cursor: pointer;
  }
  path {
    stroke: black;
  }
}
.tooltip-completed {
  path {
    stroke: $medium-grey;
  }
}

.onboarding-subtitle {
  @include base-font;
  @include font-normal;
  color: $medium-grey;
}

.onboarding-instruction-banner {
  width: 340px;
  justify-self: flex-end;
}

.onboarding-steps {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step-box {
  border: 1px solid #e1e1ee;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12),
    0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.step-box-completed {
  background-color: #f2f2f2;
  .step-title {
    color: $medium-grey;
  }
  .progress-number {
    color: $medium-grey;
  }
  .step-number {
    color: black;
  }
}

.step-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.step-title {
  @include base-font;
  @include font-normal;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
}

.step-number {
  width: 25px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(226, 124, 252, 0.3);
  text-align: center;
  padding-top: 3px;
}

.accordion-icon {
  transition: all 0.1s;
  width: 13px;
  height: 7px;
  transform: rotate(180deg);
  cursor: pointer;
}

.isFullyCompleted {
  transform: unset;
  transition: all 0.1s;
}

.progress-bar {
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: $dark-purple;
  margin-bottom: 4px;
}

.half-completed {
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 50%;
    background-color: $dark-purple;
    border-radius: 2px;
    height: 4px;
  }
}
.fully-completed {
  background-color: $dark-purple;
  height: 4px;
}

.progress-number {
  @include base-font;
  @include font-smaller;
  font-weight: 400;
  color: black;
}

.small-steps {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.small-step {
  display: flex;
  align-items: center;
  gap: 40px;
}

.small-step-title-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.small-step-title {
  @include base-font;
  @include font-small;
}

.small-step-title-completed {
  color: $medium-grey;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #e1e1ee;
  box-sizing: border-box;
}

.clickable-checkbox {
  cursor: pointer;
}

.tutorial {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $dark-purple;
  svg {
    margin-top: 2px;
    path {
      fill: $dark-purple;
    }
  }
}

.tutorial-completed {
  color: $medium-grey;
  svg {
    path {
      fill: $medium-grey;
    }
  }
}
.full-checkbox {
  width: 20px;
  height: 20px;
  background-color: $dark-purple;
  border: none;
}
</style>
