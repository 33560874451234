<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="3"
      stroke="#7B01B5"
      stroke-width="2"
    />
    <path
      :class="{ spinning: isSpinning }"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.44 0H5.56C2 0 0 2 0 5.56V14.44C0 18 2 20 5.56 20H14.44C18 20 20 18 20 14.44V5.56C20 2 18 0 14.44 0ZM16.2915 13.2052C15.2773 15.7946 12.7809 17.4988 10 17.5C6.51114 17.4774 3.61259 14.8034 3.30934 11.3277C3.00609 7.85198 5.39777 4.71644 8.83 4.09L8.67 3.92C8.34415 3.59691 8.34191 3.07085 8.665 2.745C8.98809 2.41915 9.51415 2.41691 9.84 2.74L11.33 4.22C11.4901 4.37453 11.5805 4.58749 11.5805 4.81C11.5805 5.03251 11.4901 5.24547 11.33 5.4L9.84 6.89C9.68494 7.04194 9.47708 7.12795 9.26 7.13C9.04007 7.12746 8.82926 7.0417 8.67 6.89C8.40914 6.62096 8.36386 6.20931 8.56 5.89C6.32963 6.5557 4.82894 8.64206 4.90723 10.9683C4.98552 13.2946 6.62308 15.2754 8.89316 15.7896C11.1632 16.3039 13.4945 15.2222 14.5677 13.1567C15.6408 11.0913 15.1858 8.56188 13.46 7C13.1231 6.68796 13.103 6.16189 13.415 5.825C13.727 5.48811 14.2531 5.46796 14.59 5.78C16.6304 7.66948 17.3056 10.6157 16.2915 13.2052Z"
      fill="#7B01B5"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isSpinning: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  cursor: pointer;
}
path {
  fill: $dark-grey;
  &:hover {
    fill: $dark-purple;
  }
}
.spinning {
  animation: spin 2s linear infinite;
  transform-origin: center;
  fill: $dark-purple;
  transform: scale(1.045);
}
@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1.045);
  }
  100% {
    transform: rotate(360deg) scale(1.045);
  }
}
</style>