import axios from "axios";

async function getPlan(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: {
      siteId,
    },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function updatePlan(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan/change-plan`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function removeWatermark(siteId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan/remove-watermark/${siteId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function addCreditCard(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan/add-credit-card`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getProposedPlan(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: {
      siteId,
    },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan/get-proposed-plan`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function reactivateSubscription(siteId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: { siteId },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan/re-activate`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function applyCoupon(siteId, couponCode) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: {
      couponCode,
    },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/plan/${siteId}/apply-coupon-code`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  getPlan,
  updatePlan,
  removeWatermark,
  addCreditCard,
  getProposedPlan,
  reactivateSubscription,
  applyCoupon,
};
