<template>
  <div class="product-pages-component">
    <ProductPages
      :is-product-segmentation="true"
      :segmented-products="segmentedProducts"
      @remove-product-segmentation="removeProduct"
    />
    <button
      class="action-button add-product-button"
      @click="toggleProductsModal"
    >
      {{ $t("create.bulk.pagesModal.addProduct") }}
    </button>
    <AddToCartModal
      v-if="showProductsModal"
      :is-full-screen="true"
      :selected-products-edit="getSelectedProducts(productIds)"
      :products-loading="productsLoading"
      @close-add-to-cart="showProductsModal = false"
      @submit-add-to-cart-products="submitAddToCartProducts"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AddToCartModal from "@/components/create-campaign-steps/step3/AddToCartModal.vue";
import ProductPages from "@/components/ProductPages.vue";

export default {
  components: {
    AddToCartModal,
    ProductPages,
  },
  props: {
    productIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      showProductsModal: false,
      productsLoading: false,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      getCurrency: "site/getCurrency",
      isUsingProductKey: "site/isUsingProductKey",
      siteId: "account/getSiteId",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
    }),
    segmentedProducts() {
      return this.products.filter((product) =>
        this.productIds.includes(product.productId)
      );
    },
    productListHeaders() {
      return [
        this.$t("create.bulk.pagesModal.listItems.productPages") +
          this.productIds.length,
        this.$t(""),
        this.$t(""),
        this.$t(""),
        this.$t("create.bulk.pagesModal.listItems.inventory"),
        this.$t("create.bulk.pagesModal.listItems.price"),
      ];
    },
  },
  async created() {
    await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: false,
    });
  },
  methods: {
    calculateTotalQty(productId) {
      const product = this.getProductById(productId);
      if (product && product.variants) {
        let totalQty = product.variants.reduce(
          (total, variant) => total + variant.qty,
          0
        );
        let hasUnlimited = product.variants.some(
          (variant) => variant.sellWithoutStock
        );

        if (hasUnlimited) {
          return this.$t("create.bulk.pagesModal.stock.unlimited");
        } else if (totalQty === 0) {
          return this.$t("create.bulk.pagesModal.stock.outStock");
        } else {
          return totalQty + " " + this.$t("create.bulk.pagesModal.stock.stock");
        }
      }
      return this.$t("create.bulk.pagesModal.stock.outStock");
    },
    firstVariantPrice(product) {
      if (product && product.variants && product.variants.length > 0) {
        const firstVariant = product.variants[0];
        const price = firstVariant ? firstVariant.price : 0;

        if (this.getCurrency === "$") {
          return `$${price}`;
        } else if (this.getCurrency === "€") {
          return `${price}€`;
        } else {
          return `${price}${this.getCurrency}`;
        }
      } else {
        return "";
      }
    },
    getProductById(productId) {
      //For client trois kilos sept, we are using the product key
      if (this.isUsingProductKey)
        return this.products.find(
          (product) => product.productKey === productId
        );

      return this.products.find((product) => product.productId === productId);
    },
    removeProduct(productId) {
      this.$emit(
        "update-product-segmentation",
        this.productIds.filter((id) => id !== productId)
      );
    },
    toggleProductsModal() {
      this.showProductsModal = !this.showProductsModal;
    },
    submitAddToCartProducts(payload) {
      this.productsLoading = true;
      const productIds = payload.map((product) =>
        this.isUsingProductKey ? product.productKey : product.productId
      );

      this.$emit("update-product-segmentation", productIds);

      this.productsLoading = false;
      this.showProductsModal = false;

      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.pagesModal.productAdded"),
        type: "success",
      });
    },
    getSelectedProducts(productIds) {
      const selectedProducts = this.products.filter((product) =>
        productIds.includes(product.productId)
      );

      return selectedProducts;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-product-button {
  width: fit-content;
}

.delete-icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}
</style>
