<template>
  <small-modal
    :title="$t('create.step3.replaceVideoModal.title')"
    class="replace-video-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="replace-video-modal-container">
      <div class="videos-to-change-wrapper">
        <div class="video-card-wrapper current-video">
          <h2 class="video-card-title current-video-title">
            {{ $t("create.step3.replaceVideoModal.current") }}
          </h2>
          <div class="video-section">
            <div class="video-thumbnail">
              <img :src="video.thumbnail" alt="thumbnail" class="thumbnail" />
            </div>
            <div class="video-info-wrapper">
              <p class="video-filename">{{ video.fileName }}</p>
              <p class="video-info">
                <span class="bold">{{
                  $t("create.step3.replaceVideoModal.imported")
                }}</span>
                {{ formatDate(video.creationDate) }}
              </p>
              <p class="video-info">
                <span class="bold">{{
                  $t("create.step3.replaceVideoModal.duration")
                }}</span>
                {{ formatDuration(video.videoDuration) }}
              </p>
            </div>
          </div>
        </div>
        <ArrowLeftBig />
        <div
          class="video-card-wrapper new-video"
          :class="{ 'new-video-added': newVideo }"
        >
          <div class="new-video-title-wrapper">
            <h2 class="video-card-title">
              {{ $t("create.step3.replaceVideoModal.new") }}
            </h2>
            <TrashIcon
              v-if="newVideo"
              class="trash-icon"
              @click="removeNewVideo"
            />
          </div>
          <div v-if="!isLoading && !newVideo" class="add-videos-area">
            <div
              class="upload-video-area"
              :style="uploadBlockBackground"
              @dragover.prevent="changeBackground = true"
              @dragleave.prevent="changeBackground = false"
              @drop.prevent="onFileChanged"
              @click="$refs.fileInput.click()"
            >
              <section class="flex-centered">
                <button class="upload-button">
                  {{ $t("create.step2.uploadZone.button") }}
                </button>
                <p class="upload-drop-text-floating">
                  {{ $t("create.step2.uploadZone.orDrop2") }}
                </p>
              </section>
              <input
                id="fileUploaded"
                ref="fileInput"
                class="hidden"
                type="file"
                accept="video/*"
                @change="onFileChanged"
              />
            </div>
            <button
              class="video-library-button"
              @click="openVideoLibrary = true"
            >
              <VidjetLogo class="icon-logo" />
              <p>{{ $t("create.step3.replaceVideoModal.library") }}</p>
            </button>
          </div>
          <div v-if="isLoading">
            <spinner-loader :is-replace-video="true"></spinner-loader>
            <p class="video-uploading">
              {{ $t("create.step3.replaceVideoModal.uploading") }}
            </p>
          </div>
          <div v-if="newVideo" class="video-section">
            <div class="video-thumbnail">
              <img
                :src="newVideo.thumbnail"
                alt="thumbnail"
                class="thumbnail"
              />
            </div>
            <div class="video-info-wrapper">
              <p class="video-filename">{{ newVideo.fileName }}</p>
              <p class="video-info">
                <span class="bold">{{
                  $t("create.step3.replaceVideoModal.imported")
                }}</span>
                {{ formatDate(newVideo.creationDate) }}
              </p>
              <p class="video-info">
                <span class="bold">{{
                  $t("create.step3.replaceVideoModal.duration")
                }}</span>
                {{ formatDuration(newVideo.videoDuration) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <banner-modal
        :title="$t('deleteCampaign.information')"
        :text="$t('create.step3.replaceVideoModal.banner')"
        class="formatted-banner-text"
      >
      </banner-modal>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />
        <label class="button-primary button-flex">
          <input
            class="submit-button"
            type="submit"
            :value="$t('shared.buttons.confirm')"
            @click="confirmReplacement"
          />
        </label>
      </div>
    </div>
    <template #overlay>
      <sliding-modal
        v-if="openVideoLibrary"
        class="video-library-modal-on-replace-video"
        :slide-direction="slideDirection"
        @close="openVideoLibrary = false"
      >
        <VideoLibrary
          :is-for-replace-video="true"
          @import-videos="importVideoFromLibrary"
          @close-library="openVideoLibrary = false"
        />
      </sliding-modal>
    </template>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import BannerModal from "@/components/shared/BannerModal.vue";
import ArrowLeftBig from "@/assets/svg/arrow-left-big.svg?inline";
import VidjetLogo from "@/assets/svg/vidjet-logo-library.svg?inline";
import SpinnerLoader from "@/components/shared/SpinnerLoader.vue";
import VideoLibrary from "@/components/create-campaign-steps/VideoLibrary.vue";
import SlidingModal from "@/components/shared/SlidingModal.vue";
import TrashIcon from "@/assets/svg/trash-icon.svg?inline";
import { mapGetters, mapState } from "vuex";
import { generateVideoCover } from "@/utils/generate-video-cover";

export default {
  components: {
    SmallModal,
    BannerModal,
    ArrowLeftBig,
    VidjetLogo,
    SpinnerLoader,
    VideoLibrary,
    SlidingModal,
    TrashIcon,
  },
  props: {
    index: {
      type: Number,
      default: null,
    },
    video: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      changeBackground: false,
      newVideo: null,
      isLoading: false,
      isFileTooBig: false,
      openVideoLibrary: false,
      slideDirection: "right",
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
    numberOfVideosToUpload() {
      return this.files.filter((file) => !file._id).length;
    },
    uploadBlockBackground() {
      const opacity = this.changeBackground ? "0.1" : "0.3";
      return `background: rgba(226, 124, 252, ${opacity})`;
    },
  },
  methods: {
    close() {
      if (!this.openVideoLibrary) {
        this.$emit("close");
      }
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString().slice(-2);
      return `${day}/${month}/${year}`;
    },
    formatDuration(seconds) {
      const totalSeconds = Math.floor(seconds);
      const minutes = Math.floor(totalSeconds / 60)
        .toString()
        .padStart(2, "0");
      const secs = (totalSeconds % 60).toString().padStart(2, "0");
      return `${minutes}:${secs}`;
    },
    async onFileChanged(event) {
      this.isLoading = true;
      const videoToUpload =
        event.type === "drop"
          ? event.dataTransfer.files[0]
          : event.target.files[0];

      if (!this.checkFileType(videoToUpload.name)) {
        this.isLoading = false;
        return;
      }

      this.checkFileSize(videoToUpload.size);
      if (this.isFileTooBig) {
        this.isLoading = false;
        return;
      }

      const loadedmetadataEvent = await this.processToVideoCheck(videoToUpload);
      if (!loadedmetadataEvent) {
        this.isLoading = false;
        return;
      }

      const videoMetadata = this.addMetadata(loadedmetadataEvent);
      const thumbnail = await generateVideoCover(
        URL.createObjectURL(videoToUpload),
        4
      );

      const videoObject = {
        name: videoToUpload.name,
        url: URL.createObjectURL(videoToUpload),
        thumbnail: URL.createObjectURL(thumbnail),
        creationDate: new Date().toISOString(),
        tempFile: videoToUpload,
        ...videoMetadata,
      };

      const processedVideoObject = this.setVideoObject(videoObject);

      const createdVideo = await this.createVideo(processedVideoObject);

      await this.$store.dispatch("video/addVideoToLibrary", createdVideo);

      this.newVideo = {
        ...createdVideo,
      };

      this.isLoading = false;
    },
    checkFileType(fileName) {
      const videoFormatsRegex = /(.mov|.mp4|.webm|.ogg)$/i;
      if (!videoFormatsRegex.test(fileName)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step2.alerts.supportedFormats"),
          type: "error",
        });
        return false;
      }
      return true;
    },
    checkFileSize(size) {
      const sizeInMB = size / (1024 * 1024);
      if (sizeInMB > 300) {
        this.isFileTooBig = true;
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.yourFileExceeds"),
          type: "error",
        });
      }
    },
    async processToVideoCheck(video) {
      const res = await this.isBrowserCompatible(video);
      if (!res) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.unsupportedVideo"),
          type: "error",
        });
      }
      return res;
    },
    addMetadata(event) {
      const { videoHeight, videoWidth, duration } = event;
      return {
        height: videoHeight,
        width: videoWidth,
        videoDuration: duration,
      };
    },
    async isBrowserCompatible(file) {
      const video = document.createElement("video");
      video.setAttribute("src", window.URL.createObjectURL(file));
      const result = await new Promise((resolve) => {
        video.addEventListener("loadedmetadata", (event) => {
          resolve(event.target);
        });
      });
      return result;
    },
    importVideoFromLibrary(video) {
      this.newVideo = video[0];
      this.openVideoLibrary = false;
    },
    removeNewVideo() {
      this.newVideo = null;
    },
    confirmReplacement() {
      if (this.newVideo) {
        if (this.video.cta) {
          this.newVideo.cta = { ...this.video.cta };
        }
        if (this.video.textOptions) {
          this.newVideo.textOptions = { ...this.video.textOptions };
        }
        if (this.video.isMainGrouping) {
          this.newVideo.isMainGrouping = this.video.isMainGrouping;
        }
        if (this.video.thumbnailGrouping) {
          this.newVideo.thumbnailGrouping = this.video.thumbnailGrouping;
        }
        this.$emit("replace-video", {
          index: this.index,
          newVideo: this.newVideo,
        });
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("create.step3.replaceVideoModal.successToast"),
          type: "success",
        });
        this.close();
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step3.replaceVideoModal.errorToast"),
          type: "error",
        });
      }
    },
    async createVideo(file) {
      const video = await this.$store.dispatch("video/createVideo", {
        file: {
          siteId: this.siteId,
          ...file,
        },
      });
      return video;
    },
    setVideoObject(file) {
      const videoData = {
        useCase: this.$route.query.goal,
        videoDuration: Number(file.videoDuration.toFixed(1)),
        height: file.height,
        width: file.width,
        isPremade: file.isPremade,
        _id: file._id,
      };

      videoData.tempFile = file.tempFile;
      videoData.fileName = file.name;
      videoData.url = file.url;
      videoData.thumbnail = file.thumbnail;

      return videoData;
    },
  },
};
</script>

<style lang="scss">
.replace-video-modal.modal-background {
  width: 100% !important;
}
.video-library-modal-on-replace-video.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 45%;
}
</style>

<style lang="scss" scoped>
.videos-to-change-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34px;
  margin-bottom: 24px;
}
.video-card-wrapper {
  width: 170px;
  min-height: 315px;
  background-color: rgba(243, 243, 243, 1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px 32px;
  box-sizing: border-box;
}

.current-video {
  border: 2px solid $light-grey;
}

.new-video {
  border: 2px dashed $light-grey;
  position: relative;
}

.new-video-added {
  border: 2px solid $light-grey;
}

.new-video-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.current-video-title {
  margin-bottom: 8px;
}

.video-card-title {
  font-weight: 600;
}

.video-thumbnail {
  width: 105px;
  height: 186.66px;
  border-radius: 4px;
  border: 1px solid $light-grey;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.thumbnail {
  width: 100%;
  border-radius: 4px;
}

.video-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.video-filename {
  @include base-font;
  @include font-smaller;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.video-info {
  @include base-font;
  font-size: 10px;
  color: $light-grey;
  font-weight: 500;
  .bold {
    font-weight: 600;
  }
}

.add-videos-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.upload-video-area {
  cursor: pointer;
  border-radius: $card-border-radius;
  border: 2px dashed $dark-purple;
  height: 186.66px;
  padding: 12px;
  @include flex-centered;
  background: $light-pink;
  box-sizing: border-box;
  max-width: 115px;
  text-align: center;
}
.flex-centered {
  @include flex-centered;
  flex-direction: column;
  gap: 4px;
}
.upload-button {
  font-family: "Montserrat", sans-serif;
  background: $dark-purple;
  color: white;
  @include font-small;
  padding: 2px 12px;
  border-radius: 5px;
}
.upload-drop-text-floating {
  font-size: 14px;
}
.hidden {
  display: none;
}
.video-library-button {
  @include base-font;
  @include font-small;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}
.video-uploading {
  @include base-font;
  @include font-small;
  position: absolute;
  top: 180px;
  display: flex;
  justify-content: center;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 84px;
}
.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: transparent;
  border: none;
}
.trash-icon {
  cursor: pointer;
  path {
    fill: $dark-grey;
  }
}
</style>
