import axios from "axios";

export default {
  async getProductsAddToCart(siteId, isProductListView = false) {
    const requestOptions = {
      method: "GET",
      url: `${process.env.VUE_APP_API_URL}/products`,
      headers: { "Content-Type": "application/json" },
      params: {
        siteId,
        status: "active",
        isProductListView,
      },
    };

    try {
      const res = await axios(requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async createProduct(productData) {
    const requestOptions = {
      method: "POST",
      url: `${process.env.VUE_APP_API_URL}/product`,
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await axios.post(requestOptions.url, productData, {
        headers: requestOptions.headers,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteProduct(productId) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/product/${productId}`,
        requestOptions
      );

      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async updateProduct(productId, productData) {
    const requestOptions = {
      method: "PUT",
      url: `${process.env.VUE_APP_API_URL}/product/${productId}`,
      headers: { "Content-Type": "application/json" },
    };

    try {
      const response = await axios.put(requestOptions.url, productData, {
        headers: requestOptions.headers,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  
  async forceSyncProducts(siteId) {
    const requestOptions = {
      method: "GET",
      url: `${process.env.VUE_APP_API_URL}/site/${siteId}/products/force-sync`,
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
};
