import Amplify, { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import api from "@/api";

export const cleanFileName = (fileName) => {
  // Replace if starts/ends with "ad/s"
  const startsWithAdRegex = /^ad(s?)/g;
  const endsWithAdRegex = /(ad(s?))(?=(\.))/g;
  const fileWithoutAd = fileName
    .replace(startsWithAdRegex, "_")
    .replace(endsWithAdRegex, "_");

  return fileWithoutAd.replace(/[^\w.]/g, "_").toLowerCase();
};

// Using Amplify to upload a file to an S3 bucket
export const uploadToAmplify = async (siteId, videoId, file) => {
  _configureAmplify(process.env.VUE_APP_BUCKET);
  const bucketFilePath = `${siteId}/${videoId}/${cleanFileName(file.name)}`;
  try {
    await Storage.put(bucketFilePath, file);
    return { status: "success" };
  } catch (error) {
    await api.sendLog({
      siteId: siteId,
      videoId: videoId,
      logMsg: `Error uploading file: ${error}`,
    });
    throw new Error("Error uploading file: ", error);
  }
};

export const uploadImageToAmplify = async (siteId, campaignId, file, date) => {
  // try with jpeg
  _configureAmplify(process.env.VUE_APP_BUCKET_THUMBNAIL_SOURCE);
  const bucketFilePath = `${siteId}/${campaignId}/${date}.jpg`;
  try {
    await Storage.put(bucketFilePath, file);
  } catch (error) {
    await api.sendLog({
      siteId: siteId,
      logMsg: `Error uploading file: ${error}`,
    });
    throw new Error("Error uploading file: ", error);
  }
};

// This JSON is needed to trigger the conversion process.
// The CloudFormation template we use has two types of triggers: file and metadata
// We use the 'metadata' setting which relies on a JSON with some info
// That's why we need this `createJSON` function, otherwise the compression
// process would never start.
// Check https://www.notion.so/vidjet/AWS-Pipeline-a75f7a7fce8f4815bcfce83572e2ae4f
export const createJSON = async (siteId, videoId, fileName) => {
  const jsonFileContents = {
    srcVideo: `${siteId}/${videoId}/${fileName}`,
  };

  const jsonBuffer = Buffer.from(JSON.stringify(jsonFileContents));
  _configureAmplify(process.env.VUE_APP_BUCKET);
  try {
    // replace uuid with videoId
    await Storage.put(`${siteId}/${uuidv4()}.json`, jsonBuffer);
    if (!videoId || !siteId) {
      await api.sendLog({
        siteId: siteId,
        videoId: videoId,
        logMsg: `Error uploading json: videoId or siteId is not defined`,
      });
    }
  } catch (error) {
    await api.sendLog({
      siteId: siteId,
      logMsg: `Error uploading json: ${error}`,
    });
    throw new Error("Error uploading json: ", error);
  }
};
export const startAsyncUploadToS3 = async (video, file) => {
  let vidjetFileName = cleanFileName(video.fileName);
  let s3FileName = cleanFileName(file.name);

  const s3Key = `${video.siteId}/${video._id}/${s3FileName}`;
  let result = {
    _id: video._id,
    useCase: video.useCase,
    url: `https://${process.env.VUE_APP_BUCKET}.s3-eu-west-1.amazonaws.com/${s3Key}`,
    s3Path: `s3://${process.env.VUE_APP_BUCKET}/${s3Key}`,
    key: `${s3Key}`,
    fileName: vidjetFileName,
    height: video.height,
    width: video.width,
    videoDuration: video.videoDuration,
    sizeInMB: video.sizeInMB,
  };
  video = await api.updateVideo(video._id, {
    url: result.url,
    s3Path: result.s3Path,
    key: result.key,
  });
  try {
    // we have to send the video first and then the JSON triggers the conversion
    const res = await uploadToAmplify(video.siteId, video._id, file);
    await createJSON(video.siteId, video._id, s3FileName);
    return res;
  } catch {
    return { status: "error", videoId: video._id };
  }
};

const _configureAmplify = (bucketName) => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
      region: "eu-west-1", // REQUIRED - Amazon Cognito Region
    },
    Storage: {
      AWSS3: {
        bucket: bucketName, //REQUIRED -  Amazon S3 bucket name
        region: "eu-west-1",
      },
    },
  });
  Storage.configure({
    customPrefix: {
      public: "",
    },
  });
};

export const blobUrlToFile = (blobUrl, name) => {
  return new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const file = new File([blob], name, {
          type: blob.type,
        });
        resolve(file);
      });
    });
  });
};

export const uploadThumbnailForVideo = (video) => {
  // add the date to avoid caching issues
  const date = Date.now();
  const s3ThumbnailKey = `${video.siteId}/${video._id}/${date}.jpg`;
  video.thumbnail = `https://${process.env.VUE_APP_BUCKET_THUMBNAIL_DEST}.s3.eu-west-1.amazonaws.com/${s3ThumbnailKey}`;
  video.thumbnailS3Path = `s3://${process.env.VUE_APP_BUCKET_THUMBNAIL_SOURCE}/${s3ThumbnailKey}`;
  storeThumbnailInS3(video, date);
  return video;
};

const storeThumbnailInS3 = async (video, date) => {
  // tempThumbnailFile is present only if the thumbnail was modified
  if (video && video.tempThumbnailFile) {
    const thumbnail = video.tempThumbnailFile;
    // if thumbnail empty string user removed thumbnail
    if (thumbnail !== "") {
      // we need to give ids for s3 path
      const { _id, siteId } = video;
      return await uploadThumbnailToS3(thumbnail, {
        _id: _id,
        siteId: siteId,
        date,
      });
    }
  }
};

export const uploadThumbnailToS3 = (thumbnail, video) => {
  uploadImageToAmplify(video.siteId, video._id, thumbnail, video.date);
  return video;
};
